import {
  CONFIGURATION_API_PATH,
  RESOURCES_API_PATH,
  LOGIN_API_PATH,
  INSIGHTS_API_PATH,
  BACKEND_HOST,
  SERVER_HOST,
  DATALAKE_API_HOST,
  DATALAKE_GRAPHQL_HOST,
  SEC_ADMIN_API_URL,
} from './env';

export const backendHost = BACKEND_HOST || '';
export const serverHost = SERVER_HOST || `${backendHost}/metercloud-frontend-server`;

const createPath = (path, defaultPath) => `${backendHost}${path || defaultPath}`;

export const ghostwriter = `${serverHost}/ghostwriter-api/ghostwriter`;
export const datalakeGraphql = DATALAKE_GRAPHQL_HOST;
export const datalakeApi = DATALAKE_API_HOST;
export const openApiSpecLegacy = `${backendHost}/fs/resources/flows`;
export const openApiSpec = `${backendHost}`;
export const secAdminApi = SEC_ADMIN_API_URL || '/secAdmin';
export const configuration = createPath(CONFIGURATION_API_PATH, '/utilihive-frontend-configuration-server');
export const resources = `${createPath(RESOURCES_API_PATH, '/utilihive-integration-resource-registry')}/api`;
export const login = `${createPath(LOGIN_API_PATH, '/metercloud-integration-identity')}/api`;
export const insights = `${createPath(INSIGHTS_API_PATH, '/metercloud-integration-insights')}/api/v1`;
export const insightsV2 = `${createPath(INSIGHTS_API_PATH, '/metercloud-integration-insights')}/api/v2`;
export const adr = `${serverHost}/api/adr`;
export const taskManager = `${backendHost}/utilihive-task-manager`;
export const eve = `${serverHost}/eve-api`;
