import { useMemo } from 'react';
import { defineMessage } from 'react-intl';
import EcosystemsIcon from 'connect-icons/Ecosystem';
import SimulationsIcon from 'connect-icons/Simulations';
import DeliveryProfilesIcon from 'connect-icons/DeliveryProfile';
import GhostwriterIcon from 'connect-icons/Ghostwriter';
import MODULE_NAMES from 'App/config/moduleNames';
import { FILTERS } from './filters';

export const MODULE_PATH = 'ghostwriter';

export const SIMULATIONS = '';
export const DELIVERY_PROFILES = 'deliveryprofiles';
export const ECOSYSTEMS = 'ecosystems';
export const ECOSYSTEM = ECOSYSTEMS + '/:ecosystemId';

const children = [
  {
    index: true,
    path: SIMULATIONS,
    lazy: () => import(/* webpackChunkName: "Ghostwriter:Simulations" */ '../scenes/Simulations'),
    intlMessage: defineMessage({ id: 'route.ghostwriter.simulations', defaultMessage: 'Simulations' }),
    Icon: SimulationsIcon,
    params: `?filterBars=${JSON.stringify({ [FILTERS.GW_SIMULATION_FILTER]: { filters: [`VIEW:LATEST`] } })}`,
  },
  {
    path: ECOSYSTEMS,
    lazy: () => import(/* webpackChunkName: "Ghostwriter:Ecosystems" */ '../scenes/Ecosystems'),
    intlMessage: defineMessage({ id: 'route.ghostwriter.ecosystems', defaultMessage: 'Ecosystems' }),
    Icon: EcosystemsIcon,
  },
  {
    path: ECOSYSTEM,
    lazy: () => import(/* webpackChunkName: "Ghostwriter:Ecosystem" */ '../scenes/Ecosystem'),
  },
  {
    path: DELIVERY_PROFILES,
    lazy: () => import(/* webpackChunkName: "Ghostwriter:DeliveryProfiles" */ '../scenes/DeliveryProfiles'),
    intlMessage: defineMessage({ id: 'route.ghostwriter.delivery_profiles', defaultMessage: 'Delivery Profiles' }),
    Icon: DeliveryProfilesIcon,
  },
];

const ghostwriter = {
  path: MODULE_PATH,
  lazy: () => import(/* webpackChunkName: "Ghostwriter" */ 'Ghostwriter'),
  intlMessage: defineMessage({ id: 'route.ghostwriter', defaultMessage: 'Ghostwriter' }),
  Icon: <GhostwriterIcon />,
  moduleNameForOrganisationAccess: MODULE_NAMES.GHOSTWRITER,
  children,
};

export const useRoutes = () => {
  const routes = useMemo(
    () => ({
      intlMessage: ghostwriter.intlMessage,
      Icon: ghostwriter.Icon,
      root: ghostwriter.path,
      moduleNameForOrganisationAccess: ghostwriter.moduleNameForOrganisationAccess,
      routes: ghostwriter.children
        .filter(child => !!child.intlMessage)
        .map(({ path, Icon, featureToggle, intlMessage, params, index }) => ({
          path,
          intlMessage,
          Icon,
          featureToggle,
          params,
          index,
        })),
    }),
    []
  );
  return routes;
};

export default ghostwriter;
