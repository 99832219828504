import React from 'react';
import { defineMessage } from 'react-intl';
import MODULE_NAMES from 'App/config/moduleNames';
import DocumentationIcon from 'connect-icons/Documentation';
import SMOCIcon from 'connect-icons/MeteringPoint';

export const Documentation = {
  path: 'https://docs.utilihive.io',
  intlMessage: defineMessage({ id: 'route.documentation', defaultMessage: 'Documentation' }),
  Icon: <DocumentationIcon />,
};

export const smoc = {
  path: '/smoc',
  intlMessage: defineMessage({ id: 'route.smoc', defaultMessage: 'SMOC' }),
  Icon: <SMOCIcon />,
  moduleNameForOrganisationAccess: MODULE_NAMES.SMOC,
  external: true,
};
