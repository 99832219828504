import React, { useCallback, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import * as msal from '@azure/msal-browser';
import { AD_CLIENT_ID, AD_AUTHORITY } from 'config/env';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MessageBox from 'sharedComponents/MessageBox';
import TextFieldInput from 'sharedComponents/Form/TextFieldInput';
import { FEATURE_TOGGLES } from 'App/ducks/featureDuck';
import { useFeatureToggle } from 'sharedComponents/FeatureToggle';
import { PublicPageFormPaper } from '../../presentation/components/PublicPageWrapper';
import MicrosoftLogo from '../../presentation/components/MicrosoftLogo';
import { MODULE_PRIORITY } from 'App/domain/modules';
import { getItem, removeItem } from 'util/localStorage';

export const REDIRECT_KEY = 'UTILIHIVE:REDIRECT_URL';

var msalConfig = {
  auth: {
    clientId: AD_CLIENT_ID || '6c640a36-5ca0-4f1a-a9eb-f19c499ca0be',
    authority: AD_AUTHORITY || 'https://login.microsoftonline.com/organizations/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

const shouldShowMicrosoftLogin = hostname =>
  hostname.startsWith('localhost') || /console(.*)utilihive.io/.test(hostname);

const Login = ({ error = undefined, login, token, isLoading, activeDirectory }) => {
  const [showMicrosoftLogin] = useState(shouldShowMicrosoftLogin(window.location.hostname));
  const microsoftLoginFeature = useFeatureToggle(FEATURE_TOGGLES.MICROSOFT_LOGIN);
  const intl = useIntl();
  useEffect(() => {
    msalInstance
      .handleRedirectPromise()
      .then(response => {
        if (response !== null) {
          activeDirectory(response.idToken);
        }
      })
      .catch(err => console.log(err));
  }, [activeDirectory]);

  const onClickLoginAzure = useCallback(() => {
    const tokenRequest = {
      scopes: ['User.Read'],
    };
    msalInstance.loginRedirect(tokenRequest);
  }, []);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onLoginClick = useCallback(
    ({ email, password }) => {
      login(email.toLowerCase(), password);
    },
    [login]
  );

  if (token) {
    const localStorageRedirect = getItem(REDIRECT_KEY);
    if (localStorageRedirect) {
      removeItem(REDIRECT_KEY);
      const module = MODULE_PRIORITY.find(module => localStorageRedirect.startsWith(module.path));
      if (module && module.external) {
        window.href = localStorageRedirect;
      } else {
        return <Navigate to={localStorageRedirect} />;
      }
    }
    return <Navigate to={'/'} />;
  }

  const emailLabel = intl.formatMessage({ id: 'Login-input-email', defaultMessage: 'Email' });
  const passwordLabel = intl.formatMessage({ id: 'Login-input-password', defaultMessage: 'Password' });
  const loginButtonLabel = intl.formatMessage({ id: 'Login-button-logIn', defaultMessage: 'Sign in' });
  const errorLabel =
    error &&
    (error === 'HTTP_RESPONSE_STATUS_UNAUTHORIZED'
      ? intl.formatMessage({
          id: 'Login-error-unauthorized',
          defaultMessage: 'Authentication failed, please check your email and password',
        })
      : intl.formatMessage({ id: 'Login-error-message', defaultMessage: 'An unknown error occurred' }));
  return (
    <PublicPageFormPaper>
      <form onSubmit={handleSubmit(onLoginClick)}>
        <MessageBox message={errorLabel} />
        <TextFieldInput
          registerProps={register('email', { required: true })}
          type="email"
          margin="normal"
          fullWidth
          required
          variant="outlined"
          label={emailLabel}
          autoComplete="username"
        />
        <TextFieldInput
          registerProps={register('password', { required: true })}
          type="password"
          margin="normal"
          fullWidth
          required
          variant="outlined"
          label={passwordLabel}
          autoComplete="current-password"
        />
        <Box sx={{ position: 'relative' }}>
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ my: 2 }} disabled={isLoading}>
            {loginButtonLabel}
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }}
              color="primary"
            />
          )}
        </Box>
        {(showMicrosoftLogin || microsoftLoginFeature) && (
          <Box sx={{ position: 'relative' }}>
            <Button
              sx={theme => ({
                marginBottom: 2,
                backgroundColor: theme.palette.mode === 'dark' ? 'text.primary' : 'grey.800',
                color: theme.palette.mode === 'dark' ? 'text.reverse' : 'white',
                '&:hover': {
                  backgroundColor: 'grey.400',
                },
                '&:disabled': {
                  backgroundColor: 'grey.400',
                },
              })}
              disabled={isLoading}
              fullWidth
              variant="contained"
              onClick={onClickLoginAzure}
            >
              <MicrosoftLogo sx={{ mr: 2 }} />
              <FormattedMessage id="Login-button-withMicrosoft" defaultMessage="Sign in with Microsoft" />
            </Button>
          </Box>
        )}
        <Button component={Link} to="/forgotpassword" fullWidth>
          <FormattedMessage id="Login-button-forgotPassword" defaultMessage="Forgot password?" />
        </Button>
      </form>
    </PublicPageFormPaper>
  );
};

export default Login;
