import { useMemo } from 'react';
import { defineMessage } from 'react-intl';
import DataLakeIcon from 'connect-icons/DataLake';
import GraphQLIcon from 'connect-icons/DataServices';
import WorkflowsIcon from 'connect-icons/Flow';
import OverviewIcon from 'connect-icons/Overview';

import MODULE_NAMES from 'App/config/moduleNames';

export const MODULE_PATH = 'datalake';

export const OVERVIEW = '';
export const DOCUMENTATION = 'graphql';
export const WORKFLOWS = 'workflows/*';
export const WORKFLOWS_ARCHIVE = 'archive';

const children = [
  {
    index: true,
    path: OVERVIEW,
    lazy: () => import(/* webpackChunkName: "Datalake:Overview" */ '../scenes/Overview'),
    intlMessage: defineMessage({ id: 'route.datalake.overview', defaultMessage: 'Overview' }),
    Icon: OverviewIcon,
  },
  {
    path: DOCUMENTATION,
    lazy: () => import(/* webpackChunkName: "Datalake:DataLakeGraphQL" */ '../scenes/DataLakeGraphQL'),
    intlMessage: defineMessage({ id: 'route.datalake.documentation', defaultMessage: 'Data Services' }),
    Icon: GraphQLIcon,
  },
  {
    path: WORKFLOWS,
    lazy: () => import(/* webpackChunkName: "Datalake:Workflows" */ '../scenes/Workflows'),
    intlMessage: defineMessage({ id: 'route.datalake.workflows', defaultMessage: 'Workflows' }),
    Icon: WorkflowsIcon,
  },
];

const datalake = {
  path: MODULE_PATH,
  lazy: () => import(/* webpackChunkName: "Datalake" */ 'Datalake'),
  intlMessage: defineMessage({ id: 'route.datalake', defaultMessage: 'Datalake' }),
  Icon: <DataLakeIcon />,
  moduleNameForOrganisationAccess: MODULE_NAMES.DATALAKE,
  children,
};

export const useRoutes = () => {
  return useMemo(
    () => ({
      intlMessage: datalake.intlMessage,
      root: datalake.path,
      Icon: datalake.Icon,
      routes: datalake.children
        .filter(child => !!child.intlMessage)
        .map(({ intlMessage, Icon, path, index }) => ({
          intlMessage,
          Icon,
          path,
          index,
        })),
    }),
    []
  );
};

export default datalake;
