import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import app from '../App/ducks';
import settings from '../Settings/ducks';
import heartbeat from '../Heartbeat/ducks';
import { jwtMiddleware, jwtListener, jwtReducer } from './jwtMiddleware';

const initializeStore = () => {
  const rootReducer = combineReducers({
    settings,
    app,
    heartbeat,
    token: jwtReducer,
  });

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(jwtMiddleware, thunk)));
  store.subscribe(() => jwtListener(store.getState()));

  return store;
};

export default initializeStore;
